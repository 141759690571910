import { useSelector } from "react-redux";
import { Header } from "../../../../_components/Admin/Header";
import { useParams } from "react-router-dom";
import { apiCaller, history } from "../../../../_helpers";
import './DetailAssignedHomeWorkStudent.style.scss'
import { useState } from "react";
import moment from 'moment'

export default function DetailAssignedHomeWorkStudent() {
  const authentication = useSelector((state) => state.authentication);
  const { id, turn_id, student_id } = useParams();
  const heading = new URLSearchParams(window.location.search).get("heading");
  const startTime = new URLSearchParams(window.location.search).get("startTime");
  const endTime = new URLSearchParams(window.location.search).get("endTime");
  const [listData, setListData] = useState([])

  const getData = async () => {
    const url = '/api_exercise_report/turn_user_lesson_details?turn_id=' + turn_id + '&student_id=' + student_id
    try {
      const res = await apiCaller(url, 'get')
      if(res?.status) {
        setListData(res?.data)
      }
    } catch (error) { 
    }
  }

  useState(() => {
    getData()
  }, [])

  return (
    <div className="sunE-right-container rel detail-assigned-home-work-page">
      <Header
        title={heading}
        isBack
        clickBack={() =>
          history.push("/" + authentication.role + "/class/view/" + id + "/exercise/detail-assigned/" + turn_id + '?startTime=' + startTime + '&endTime=' + endTime + '&indexTab=0' )
        }
      />
      <div className="sunE-content sunE-class-content">
        <div className="turn-user-lesson-detail-container scrollbar-custom">
          <div className="turn-user-lesson-detail-content">
            {listData?.map(item => (
              <div key={item?.lesson_id} className="turn-user-lesson-detail-item">
                <div className="turn-user-lesson-detail-avatar">
                  <img 
                    src={
                      "/assets/images/teacher/giaobai/" +
                      item.skill?.toLocaleLowerCase() +
                      ".png"
                    } 
                  />
                </div>
                <div className="turn-user-lesson-detail-info">
                  <div className="turn-user-lesson-detail-name">
                    {item?.level && <span 
                      style={{
                        backgroundColor: item?.level === 'easy' ? '#84C241' : item?.level === 'normal' ? '#F8AF36' : '#EB5757' , 
                        backgroundColor: '#84C241',
                        textTransform: 'capitalize'
                      }}
                    >
                      {item?.level === 'normal' ? 'Medium' : item?.level}
                    </span>}
                    {item?.lesson_name}
                  </div>
                  <p style={{fontSize: '18px'}}>{item?.curriculum_name + ' - Unit ' + item?.unit_number}</p>
                  <div className="flex flex-m">
                    <span>
                      <span style={{color: !!item?.status ? '#84C241' : '#EB5757'}}>{!!item?.status ? 'Đã làm' : 'Chưa làm'}</span>
                      {(!!item?.status || item?.is_overdue) && <span>{' - '}</span>}
                      {(!!item?.status || item?.is_overdue) && <span style={{color: item?.is_overdue ? '#EB5757' : '#84C241'}}>{item?.is_overdue ? 'Quá hạn' : 'Đúng hạn'}</span>}
                    </span>
                    {item?.date && <span>{'Làm ngày ' + moment(item.date).format('DD/MM/YYYY')}</span>}
                    <span>
                      Điểm:
                      {' '}
                      <span style={{color: '#00BEB4', fontWeight: 'bold'}}>{item?.score !== null && Number(item?.score) >= 0 ? Math.round(Number(item.score) * 100)/100 : '__'}</span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}