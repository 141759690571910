import { useParams } from "react-router-dom";
import { Header } from "../../../../_components/Admin/Header";
import { apiCaller, history } from "../../../../_helpers";
import { useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import './DetailAssignedHomeWorkExercise.style.scss'

export default function DetailAssignedHomeWorkExercise() {
  const authentication = useSelector((state) => state.authentication);
  const { id, turn_id, lesson_id, unit_id } = useParams();
  const heading = new URLSearchParams(window.location.search).get("heading");
  const startTime = new URLSearchParams(window.location.search).get("startTime");
  const endTime = new URLSearchParams(window.location.search).get("endTime");
  const [listData, setListData] = useState([])
  const [totalStudent, setTotalStudent] = useState(0);
  const [isHasStudentDone, setIsHasStudentDone] = useState(false);
  const [isHideRatio, setIsHideRatio] = useState(false);
  const [totalQuestion, setTotalQuestion] = useState(0);
  const getData = async () => {
    const url = '/api_exercise_report/turn_lesson_report_details' + '?turn_id=' + turn_id + '&lesson_id=' + lesson_id + '&unit_id=' + unit_id
    try {
      const res = await apiCaller(url, 'get')
      if(res?.status) {
        const listNumberQuestion = Array(res?.total_question).fill(0).map((_, index) => index + 1);
        const listTotalStudentRight = Array(res?.total_question).fill(0);
        res?.data?.map(item => {
          item?.lesson_data?.map((lesson, index) => {
            if(lesson?.do_right) {
              listTotalStudentRight[index] += 1
            }
          })
        })
        const infoQuestion = res?.data_question?.[0]?.list_option?.[0] || res?.data_question?.list_option?.[0]
        if (infoQuestion?.type == 'speaking' && infoQuestion?.question_type == 2 ||
          infoQuestion?.type == 'speaking' && infoQuestion?.question_type == 3 ||
          infoQuestion?.type == 'pronunciation' && infoQuestion?.question_type == 1 ||
          infoQuestion?.type == 'pronunciation' && infoQuestion?.question_type == 3 ||
          infoQuestion?.type == 'writing' && infoQuestion?.question_type == 7 ||
          infoQuestion?.type == 'project'
        ) {
          setIsHideRatio(true);
        }
        setTotalQuestion(res?.total_question)
        setTotalStudent(res?.data?.length)
        setIsHasStudentDone(listTotalStudentRight.some(item => item > 0))
        setListData([{listNumberQuestion}, {listTotalStudentRight}, ...res?.data])
      }
    } catch (error) { 
    }
  };

  const renderItem = ({item, index}) => {
    const isDone = index > 1 && !!item?.lesson_data?.length;
    const countNumberRight = index > 1 ? item?.lesson_data?.filter(lesson => lesson?.do_right)?.length : 0

    return (
      <div className="turn_lesson_report_detail-item">
        <div 
          className="turn_lesson_report_detail-name" 
          style={{
            color: index === 1 ? '#00BEB4' : '#4D4D4D',
            fontWeight: index === 1 ? 'bold' : 'normal',
          }}
        >
          {index === 0 ? '' : index === 1 ? (isHideRatio ? '' : 'Tỷ lệ làm đúng') : item?.name}
        </div>
        <div className="turn_lesson_report_detail-box" style={{borderWidth: index > 1 && !isHideRatio ? 1 : 0, height: index === 1 && isHideRatio ? 'unset' : 28}}>
          <span className="font-weight-bold">{index === 0 ? 'Câu' : (index === 1 || isHideRatio) ? '' : isDone ? `${countNumberRight}/${totalQuestion}` : '___'}</span>
        </div>
        {index === 0 && item?.listNumberQuestion?.map(numberQuestion => (
          <div
            key={numberQuestion}
            className="turn_lesson_report_detail-box"
            style={{borderWidth: 1}}
          >
            <span>{numberQuestion}</span>
          </div>
        ))}
        {index === 1 && !isHideRatio && item?.listTotalStudentRight?.map((numberRight, iNumRight) => (
          <div
            key={iNumRight}
            className="turn_lesson_report_detail-box"
            style={{borderWidth: 1, borderColor: '#00BEB4'}}
          >
            <span className="font-weight-bold" style={{color: '#00BEB4'}}>{isHasStudentDone ? `${numberRight}/${totalStudent}` : '___'}</span>
          </div>
        ))}
        {index > 1 && 
          <>
            {!!item?.lesson_data?.length ? item?.lesson_data?.map(lesson => (
          <div
            key={lesson?.id}
            className="turn_lesson_report_detail-box"
          >
            <img 
              className="turn_lesson_report_detail-box" 
              src={lesson?.do_right ? '/assets/images/icon/icon_tick_rectangle.png' : '/assets/images/icon/icon_swrong_rectangle.png'} 
              style={{objectFit: 'cover'}}/>
          </div>
        )) : (
          <>
            {!!item?.user_choice?.length ? item?.user_choice?.map(choice => (
              <div
              key={choice?.question_id}
              className="turn_lesson_report_detail-box" 
              style={{borderWidth: 1}}
            >
              <span className="font-weight-bold">{Math.round((choice?.question_score * 100 || 0) * 10) / 10}%</span>
            </div>
            )) : (
              Array(totalQuestion).fill(0).map((_, i) => (
                <div
                  key={i}
                  className="turn_lesson_report_detail-box" 
                  style={{borderWidth: 1}}
                >
                  <span className="font-weight-bold">___</span>
                </div>
              ))
            )}
          </>
        )}
          </>
        }
      </div>
    )
  }

  useEffect(() => {
    getData()
  }, [])
  
  return (
    <div className="sunE-right-container rel detail-assigned-home-work-page">
      <Header
        title={heading}
        isBack
        clickBack={() =>
          history.push("/" + authentication.role + "/class/view/" + id + "/exercise/detail-assigned/" + turn_id + '?startTime=' + startTime + '&endTime=' + endTime + '&indexTab=1' )
        }
      />
      <div className="sunE-content sunE-class-content">
        <div className="turn_lesson_report_detail-container scrollbar-custom">
          <div className="turn_lesson_report_detail-list">
            {listData?.map((item, index) => (
              <Fragment key={index}>
                {renderItem({item, index})}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}